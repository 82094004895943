export default {
  labels: {
    open: 'Artikel anzeigen',
    collapse: 'Artikel zuklapen',
    in_stock: 'Auf Lager',
    out_of_stock: 'Nicht auf Lager',
  },
  list: {
    name: 'Artikel',
    price: 'Preis',
    is_available: 'Verfügbar/Nicht vorrätig',
  },
};
