import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function OrdersPausedFlicker({
  onClick,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Flickers:OrdersPausedFlicker' });

  return (
    <Container onClick={onClick}>
      <Wrapper>
        <CounterCircle>
          <CounterText>!</CounterText>
        </CounterCircle>
        <CounterLabelText>{t('header')}</CounterLabelText>
        <HelperText>{t('text')}</HelperText>
      </Wrapper>
    </Container>
  );
}

const Container = tw.div`z-1200 h-screen w-screen bg-red-500 fixed inset-0 flex justify-center cursor-pointer p-4`;
const Wrapper = tw.div`flex-col m-auto`;
const CounterCircle = tw.div`h-80 w-80 rounded-full bg-red-600 m-auto flex`;
const CounterText = styled.h1`
  ${tw`m-auto text-white`}
  font-size: 350px;
`;
const CounterLabelText = tw.h2`m-auto text-white mt-8 text-center text-3xl font-semibold`;
const HelperText = tw.h3`m-auto mt-2 text-white text-center text-lg font-normal`;
