import React from 'react';
import { Helmet } from 'react-helmet';
import { Favicon } from '../../resources/assets/images/favicons';

export default function MetaInfo() {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" id="favicon" href={Favicon} />
        <meta property="og:image" content={Favicon} />
        <meta name="twitter:image" content={Favicon} />
      </Helmet>
    </div>
  );
}
