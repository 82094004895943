import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'eventHelper',
  initialState: {
    event: {
      type: '',
      payload: null,
    },
  },
  reducers: {
    setEvent: (state, action) => {
      try {
        state.event = action.payload;
      } catch (error) {
        console.error('error setEvent: ', error);
      }
    },
    resetEvent: (state) => {
      try {
        state.event = {
          type: '',
          payload: null,
        };
      } catch (error) {
        console.error('error resetEvent: ', error);
      }
    },
  },
});

export const eventHelperProps = (state) => ({
  event: state.event,
});
