export default {
  header: 'Menu',
  tabs: {
    menu: 'Menu',
    modifier_groups: 'Modifiers',
  },
  toasts: {
    failed_to_load_data: 'Failed to load menu. Please refresh and try again',
  },
};
