export default {
  title: 'Enter refund amount',
  description: 'Available for refund: {{amount}}',
  form: {
    amount: {
      label: 'Refund',
    },
    reason: {
      label: 'Reason',
      options: {
        requested_by_customer: 'requested by customer',
        reclamation: 'reclamation',
        communication_error: 'communication error',
        other: 'other',
      },
    },
    manual_reason: {
      label: 'Enter refund reason',
    },
  },
  feedback: {
    amount_too_small: 'Minimum refund amount {{amount}}',
    amount_too_big: 'Maximum refund amount {{amount}}',
  },
  buttons: {
    refund: 'Refund',
    cancel: 'Cancel',
  },
};
