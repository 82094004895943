import {
  CFormInput,
  CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
} from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import collect from 'collect.js';
import Button, { Types, Variants } from '../../../buttons/Button';
import useHelpers from '../../../../hooks/useHelpers';
import ChevronLeftIcon from '../../../../resources/assets/icons/ChevronLeftIcon';
import FeedbackLabel from '../../../form/FeedbackLabel';
import { DeliveryProvider, ERROR_CODES, ORDER_METHODS } from '../../../../services/exports/Constants';
import HelperMethods from '../../../../services/exports/HelperMethods';
import PrimaryOrderButton from '../../../buttons/PrimaryOrderButton';
import PrimaryOrderBadge from '../../../badge/PrimaryOrderBadge';

export const steps = {
  initial: 'initial',
  confirmation: 'confirmation',
  change_time: 'change_time',
};

export default function MultiStepOrderConfirmation({
  order,
  preparationTime,
  setPreparationTime,
  pickupTime,
  minutesFromNow,
  isOrderAcceptable,
  loading,
  printing,
  acceptOrder,
  toggleModal,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:MultiStepOrderConfirmation' });

  const { company } = useSelector((state) => state.company);

  const { formatTime } = useHelpers();

  const [step, setStep] = useState(steps.initial);
  const [useCustomTime, setUseCustomTime] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  const isExternalDelivery = order.method === ORDER_METHODS.delivery && order.delivery_provider !== DeliveryProvider.Standalone;

  const timeStep = company?.order_approval_time_step ?? 10;
  const minPreparationTime = useMemo(
    () => {
      if (!isExternalDelivery) {
        return null;
      }

      return order.delivery_provider === DeliveryProvider.WoltDrive ? 15 : 30;
    },
    [isExternalDelivery, timeStep],
  );
  const maxPreparationTime = useMemo(
    () => {
      if (isExternalDelivery && order.delivery_provider === DeliveryProvider.WoltDrive) {
        return 60;
      }

      return timeStep * 9 < 60 ? 60 : timeStep * 8;
    },
    [isExternalDelivery, timeStep],
  );

  const selectedTimeValid = !useCustomTime
    || (
      (!minPreparationTime || minPreparationTime <= selectedTime)
      && maxPreparationTime >= selectedTime
    );

  useEffect(() => {
    selectedTime && setPreparationTime(+selectedTime);
  }, [selectedTime]);

  const timeOptions = useMemo(
    () => collect().times(
      6,
      (i) => ((minPreparationTime ?? order.estimated_preparation_time) + company.busy_mode_minutes - timeStep) + (timeStep * i),
    ).filter((item) => item <= maxPreparationTime).toArray(),
    [
      minPreparationTime,
      order.estimated_preparation_time,
      company.busy_mode_minutes,
      preparationTime,
      timeStep,
      maxPreparationTime,
    ],
  );

  const _acceptOrder = async () => {
    const { success, status } = await acceptOrder();

    if (
      !success
      && status === 412
    ) {
      setStep(steps.initial);
    }
  };

  const goBack = () => ({
    [steps.initial]: () => null,
    [steps.confirmation]: () => setStep(steps.initial),
    [steps.change_time]: () => setStep(steps.confirmation),
  })[step]();

  const onSelectTime = (option) => {
    setSelectedTime(option);
    setUseCustomTime(false);
  };

  const renderInitialStep = () => (
    <>
      <h5 className="w-full text-center">{t('title')}</h5>
      <div className="w-full d-flex justify-content-center">
        <PrimaryOrderBadge order={order}>
          {minutesFromNow}
          {' '}
          min
        </PrimaryOrderBadge>
      </div>
      <div className="w-full text-center website-text bold mt-1">
        {formatTime(pickupTime)}
      </div>
      <PrimaryOrderButton
        order={order}
        title={(
          <div className="website-text bold white p-1">
            {t('buttons.continue')}
          </div>
        )}
        onClick={() => setStep(steps.confirmation)}
        className="w-100 mt-4 sm-mt-3"
      />
    </>
  );

  const renderConfirmationStep = () => (
    <CModal
      id="multi-step-order-confirmation"
      visible
      alignment="center"
      scrollable
      size="xl"
      fullscreen
      onClose={() => toggleModal()}
    >
      <CModalHeader closeButton={false}>
        <button
          className="tw-h-8 tw-w-8 tw-aspect-square tw-rounded-full bg-black tw-flex tw-justify-center tw-border-none"
          onClick={goBack}
        >
          <ChevronLeftIcon className="tw-h-3 tw-w-3 my-auto" color="white" />
        </button>
        <CModalTitle className="website-text bold">{t('header')}</CModalTitle>
        <CIcon
          icon={cilX}
          size="xxl"
          onClick={() => toggleModal()}
          className="cursor-pointer"
        />
      </CModalHeader>
      <CModalBody className="p-3">
        <h5 className="w-full text-center">{t('title')}</h5>
        {step === steps.confirmation ? (
          <div>
            <h5 className="mt-2 w-full text-center d-flex justify-content-center">
              <span className="my-auto">{t('labels.pickup_in')}</span>
              <PrimaryOrderBadge order={order} className="ml-2 my-auto">
                {minutesFromNow}
                {' '}
                min
              </PrimaryOrderBadge>
            </h5>
            <h5 className="mt-2 w-full text-center">{t('labels.can_you_make_it')}</h5>
            <p className="mt-2 w-full text-center website-text tw-text-brand-inkGrey-grey_4">{t('labels.we_also_send_this_update_to_the_customer')}</p>
          </div>
        ) : (
          <>
            <p className="mt-2 w-full text-center website-text tw-text-brand-inkGrey-grey_4">{t('labels.we_also_send_this_update_to_the_customer')}</p>
            <div className="tw-grid tw-grid-cols-3 tw-gap-4">
              {timeOptions.map((item, index) => (
                <Button
                  title={`${item} min`}
                  type={(index < timeOptions.length - 2 || (!useCustomTime && item === selectedTime)) ? Types.primary : Types.warning}
                  variant={index >= timeOptions.length - 2 || (!useCustomTime && item === selectedTime) ? Variants.fill : Variants.outline}
                  onClick={() => onSelectTime(item)}
                  className="tw-h-16"
                  key={`time-option-${index}`}
                />
              ))}
              <Button
                type={useCustomTime ? Types.primary : Types.warning}
                variant={Variants.fill}
                title={t('buttons.custom')}
                className="tw-col-span-3 tw-h-16"
                onClick={() => setUseCustomTime(true)}
              />
            </div>
            {useCustomTime && (
              <div>
                <CFormInput
                  type="number"
                  autoFocus
                  value={selectedTime}
                  placeholder={t('form.preparation_time.placeholder')}
                  className="mt-3"
                  onChange={(e) => setSelectedTime(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
                {useCustomTime && (!selectedTime || selectedTime === 0) && (
                  <FeedbackLabel
                    type="error"
                    className="mt-3"
                    message={t('feedback.preparation_time_required')}
                  />
                )}
                {minPreparationTime && minPreparationTime > selectedTime && (
                  <FeedbackLabel
                    type="error"
                    className="mt-3"
                    message={t('feedback.min_preparation_time', { minutes: minPreparationTime })}
                  />
                )}
                {selectedTime > maxPreparationTime && (
                  <FeedbackLabel
                    type="error"
                    className="mt-3"
                    message={t('feedback.max_preparation_time', { minutes: maxPreparationTime })}
                  />
                )}
              </div>
            )}
          </>
        )}
      </CModalBody>
      <CModalFooter>
        <div className="w-full">
          <PrimaryOrderButton
            order={order}
            title={step === steps.confirmation ? t('buttons.yes_lets_go') : t('buttons.confirm')}
            disabled={!selectedTimeValid || !isOrderAcceptable}
            onClick={_acceptOrder}
            loading={loading || printing}
            className="w-full"
          />
          {step === steps.confirmation && (
            <Button
              type={Types.secondary}
              title={t('buttons.no_change_time')}
              onClick={() => setStep(steps.change_time)}
              className="w-full mt-2"
            />
          )}
        </div>
      </CModalFooter>
    </CModal>
  );

  return step === steps.initial ? renderInitialStep() : renderConfirmationStep();
}
