import { ORDER_REJECTION_REASONS } from '../../../../../../../services/exports/Constants';

export default {
  statuses: {
    rejected: 'Storniert: {{reason}}',
    fulfilled: 'Dies ist eine alte Bestellung',
  },
  rejection_reasons: {
    [ORDER_REJECTION_REASONS.Automatic]: 'Nicht angenommen',
    [ORDER_REJECTION_REASONS.ClosingSoon]: 'Bald geschlossen',
    [ORDER_REJECTION_REASONS.ItemUnavailable]: 'Artikel nicht mehr verfügbar',
    [ORDER_REJECTION_REASONS.OrderTooBig]: 'Bestellung zu groß',
    [ORDER_REJECTION_REASONS.CustomerMistake]: 'Kundenfehler',
    [ORDER_REJECTION_REASONS.Other]: 'Andere',
    fully_refunded: 'Bestellung wurde vollständig zurückerstattet',
  },
};
