import React from 'react';
import tw, { styled } from 'twin.macro';
import collect from 'collect.js';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Logo from '../../resources/assets/icons/Logo';
import useStore from '../../hooks/useStore';
import useMaps from '../../hooks/useMaps';
import {
  DeliveryProvider,
  INNER_PRINTER_RECEIPT_ELEMENT_ID,
  LINE_ITEM_TYPES, ORDER_METHODS,
  PAYMENT_METHODS, TAX_BEHAVIOUR,
  THERMAL_PRINTER_SIZES,
} from '../../services/exports/Constants';
import useHelpers from '../../hooks/useHelpers';
import DeliveryProviderIcon from '../delivery/DeliveryProviderIcon';

export default function InnerPrinterReceipt(props) {
  const { payload: order } = props;

  // eslint-disable-next-line max-len
  const { t: translate } = useTranslation(null, { keyPrefix: 'Components:Print:InnerPrinterReceipt' });

  const company = useStore()?.company?.company;

  const lng = company?.legal_lang ?? 'de';

  const {
    formatCurrency,
    formatTime,
    formatDateTime,
    formatProductName,
  } = useHelpers();
  const { getGoogleMapsUrl } = useMaps();

  const pageWidth = window.innerWidth < THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM
    ? (window.innerWidth * (window.innerWidth / THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM))
    : THERMAL_PRINTER_SIZES.FIFTY_EIGHT_MM;

  const { payment_method_id } = order.payment;

  const isCashPayment = payment_method_id === PAYMENT_METHODS.CASH;
  const isExternalDelivery = order?.method === ORDER_METHODS.delivery && order?.delivery_provider !== DeliveryProvider.Standalone;

  function t(key, config) {
    return translate(key, { lng, ...config });
  }

  function groupModifiers(lineItem) {
    try {
      return collect(lineItem.modifier_groups)
        .map((item) => ({
          ...item,
          modifiers: collect(lineItem.modifiers)
            .where('modifier_group_id', item.id)
            .toArray(),
        }))
        .toArray();
    } catch (error) {
      return [];
    }
  }

  const renderLogo = () => company?.service_provider?.is_master && (
    <LogoContainer>
      <Logo color="black" width={pageWidth * 0.4} />
      {isExternalDelivery && (
        <DeliveryProviderIcon
          provider={order.delivery_provider}
          width={pageWidth * 0.4}
          color="black"
          className="ml-2"
        />
      )}
    </LogoContainer>
  );

  const renderCustomerInfo = () => (
    <Section>
      <H2 negativeOffset>{order?.is_dummy ? t('customer_info.dummy_order') : `#${order?.number}`}</H2>
      <H2>
        {order.customer_first_name}
        {' '}
        {order.customer_last_name}
      </H2>
      <Paragraph>
        Tel:
        {' '}
        {order.customer_phone_number}
      </Paragraph>
      <Paragraph>
        {t('customer_info.ordered_at')}
        {' '}
        {formatDateTime(order?.created_at, { dateFormat: 'DD/MM/YYYY' }, lng)}
      </Paragraph>
    </Section>
  );

  const renderDeliveryInfo = () => (
    <DeliveryInstructionContainer>
      <H3>{t('delivery_info.info')}</H3>
      {order?.delivery_street_name && order?.delivery_street_number && (
        <Paragraph>
          {order?.delivery_street_name}
          {' '}
          {order?.delivery_street_number}
        </Paragraph>
      )}
      {order?.delivery_zip_code && order?.delivery_city && (
        <Paragraph>
          {order?.delivery_zip_code}
          {' '}
          {order?.delivery_city}
        </Paragraph>
      )}
      {order?.delivery_doorbell_name && (
        <Paragraph>{order?.delivery_doorbell_name}</Paragraph>
      )}
      {order?.delivery_company_name && (
        <Paragraph>{order?.delivery_company_name}</Paragraph>
      )}
      {order?.customer_note && (
        <Paragraph>
          {t('delivery_info.note')}
          {'" '}
          {order?.customer_note}
          {'" '}
        </Paragraph>
      )}
    </DeliveryInstructionContainer>
  );

  const renderScheduleInfo = () => {
    function getOrderMethod() {
      switch (order?.method) {
        case ORDER_METHODS.delivery:
          return t('schedule_info.delivery');
        case (ORDER_METHODS.room_service):
          return t(
            `schedule_info.room_service_location.${order?.room_service_location?.type}`,
            { details: order?.room_service_details },
          ) + (order?.room_service_details ? ` #${order?.room_service_details}` : '');
        default:
          return t('schedule_info.pickup');
      }
    }

    return (
      <Section borderTop>
        <DeliveryInfoContainer>
          <H2 center noMargin negativeOffset>
            {getOrderMethod()}
          </H2>
          {order?.room_service_location && (
            <>
              <Paragraph center>{order?.room_service_location?.name}</Paragraph>
              <Paragraph center>
                {order?.room_service_location?.street_name}
                {' '}
                {order?.room_service_location?.street_number}
                {', '}
                {order?.room_service_location?.zip_code}
                {' '}
                {order?.room_service_location?.city}
              </Paragraph>
            </>
          )}
          {order?.scheduled_for && !moment(order?.scheduled_for).isSame(moment(), 'day') && (
            <ScheduleDate>
              {formatDateTime(order?.scheduled_for, { format: 'dddd, D MMMM' }, lng)}
            </ScheduleDate>
          )}
          {order?.scheduled_for ? (
            <DesiredTime>
              {t('schedule_info.wish_time')}
              {' '}
              {formatTime(order?.scheduled_for)}
            </DesiredTime>
          ) : null}
          <ConfirmTime>
            {isExternalDelivery ? t('schedule_info.driver_arrival_time') : t('schedule_info.confirmed_time')}
            {' '}
            <Bold>
              {formatTime(order?.estimated_pickup_time)}
            </Bold>
          </ConfirmTime>
          {isExternalDelivery && (
            <CenterContainer>
              <Paragraph center italic>
                {t('schedule_info.external_delivery', { carrier: t(`delivery_providers.${order.delivery_provider}`) })}
              </Paragraph>
            </CenterContainer>
          )}
        </DeliveryInfoContainer>
        {order?.order_instructions?.length > 0 && (
          <Paragraph>
            {t('schedule_info.order_instructions')}
            {' '}
            {order?.order_instructions}
          </Paragraph>
        )}
        {order?.method === ORDER_METHODS.delivery && renderDeliveryInfo()}
      </Section>
    );
  };

  const renderProducts = () => (
    <Section borderTop>
      <H3>{t('products.item')}</H3>
      {order?.line_items?.map((lineItem, index) => {
        const isEndOfList = order?.line_items && index === (order?.line_items?.length ?? 0) - 1;

        return (
          <ProductContainer
            noMargin={isEndOfList}
            underline={!isEndOfList}
            key={`line-item-${lineItem.id}`}
          >
            <LabelValue containerProps={{
              bottomMargin: true,
            }}
            >
              <Paragraph bold big>
                {lineItem?.quantity}
                x
                {' '}
                {formatProductName(lineItem)}
              </Paragraph>
              <Paragraph bold>
                {formatCurrency(lineItem?.product_original_price)}
              </Paragraph>
            </LabelValue>
            {lineItem?.note && (
              <Paragraph>
                {'> >'}
                {' '}
                {lineItem.note}
              </Paragraph>
            )}
            {lineItem?.type === LINE_ITEM_TYPES.COMBO ? lineItem?.children?.map((child) => (
              <ModifierGroupContainer key={`line-item-${child.id}`}>
                <Paragraph big>{formatProductName(child)}</Paragraph>
                {child?.modifiers?.map((modifier) => (
                  <ModifierContainer key={`line-item-${child.id}-modifier-${modifier.id}`}>
                    <LabelValue>
                      <Row>
                        <Paragraph big rightPadding>
                          •
                        </Paragraph>
                        <Paragraph big>
                          {modifier?.name}
                        </Paragraph>
                      </Row>
                      <Paragraph>
                        {formatCurrency((modifier?.original_price ?? 0))}
                      </Paragraph>
                    </LabelValue>
                  </ModifierContainer>
                ))}
              </ModifierGroupContainer>
            )) : groupModifiers(lineItem).map((modifierGroup) => (
              <ModifierGroupContainer key={`line-item-${lineItem.id}-modifier-group-${modifierGroup.id}`}>
                {!company?.is_modifier_group_hidden_on_receipt && (
                  <Paragraph big>{modifierGroup?.name}</Paragraph>
                )}
                {modifierGroup.modifiers.map((modifier) => (
                  <ModifierContainer key={`line-item-${lineItem.id}-modifier-group-${modifierGroup.id}-modifier-${modifier.id}`}>
                    <LabelValue>
                      <Paragraph big>
                        -
                        {' '}
                        {modifier?.name}
                      </Paragraph>
                      <Paragraph>
                        {formatCurrency((modifier?.original_price ?? 0) * (lineItem.quantity ?? 0))}
                      </Paragraph>
                    </LabelValue>
                  </ModifierContainer>
                ))}
              </ModifierGroupContainer>
            ))}
          </ProductContainer>
        );
      })}
    </Section>
  );

  const renderPaymentBreakDown = () => !company?.is_price_section_hidden_on_receipt && (
    <Section borderTop>
      <H3>{t('payment_breakdown.price')}</H3>
      <LabelValue>
        <Paragraph>{t('payment_breakdown.subtotal')}</Paragraph>
        <Paragraph>{formatCurrency(order?.original_price)}</Paragraph>
      </LabelValue>
      {order?.method_fee > 0 && (
        <LabelValue>
          <Paragraph>{t('payment_breakdown.delivery_fee')}</Paragraph>
          <Paragraph>{formatCurrency(order?.method_fee)}</Paragraph>
        </LabelValue>
      )}
      {order?.tip > 0 && (
        <LabelValue>
          <Paragraph>{t('payment_breakdown.tips')}</Paragraph>
          <Paragraph>{formatCurrency(order?.tip)}</Paragraph>
        </LabelValue>
      )}
      {order?.service_fee > 0 && (
        <LabelValue>
          <Paragraph>{t('payment_breakdown.processing_fee')}</Paragraph>
          <Paragraph>{formatCurrency(order?.service_fee)}</Paragraph>
        </LabelValue>
      )}
      {order?.payment_method_upcharge_fee > 0 && (
        <LabelValue>
          <Paragraph>{t(`payment_breakdown.payment_method_upcharge_fee.${payment_method_id}`)}</Paragraph>
          <Paragraph>{formatCurrency(order?.payment_method_upcharge_fee)}</Paragraph>
        </LabelValue>
      )}
      {order?.total_discount > 0 && (
        <LabelValue>
          <Paragraph>{t('payment_breakdown.promo_applied')}</Paragraph>
          <Paragraph>
            -
            {formatCurrency(order?.total_discount)}
          </Paragraph>
        </LabelValue>
      )}
      {order?.tax_behaviour === TAX_BEHAVIOUR.exclusive && order.tax > 0 && (
        <div className="d-flex justify-content-between website-text mt-3">
          <div>{t('payment_breakdown.tax')}</div>
          <div>{formatCurrency(order.tax)}</div>
        </div>
      )}
      <LabelValue>
        <Paragraph bold>
          {isCashPayment ? t('payment_breakdown.total') : t('payment_breakdown.paid_amount')}
        </Paragraph>
        <Paragraph bold>
          {formatCurrency(order?.total_amount)}
        </Paragraph>
      </LabelValue>
      {isCashPayment ? (
        <CenterContainer>
          <H3 center noUnderline noMargin>{t('payment_breakdown.order_not_paid')}</H3>
          <H3 center noUnderline regular noMargin>{t('payment_breakdown.cash')}</H3>
        </CenterContainer>
      ) : (
        <CenterContainer>
          <H3 center noUnderline noMargin>{t('payment_breakdown.order_paid_online')}</H3>
        </CenterContainer>
      )}
      <CenterContainer>
        <Paragraph center>{t('payment_breakdown.this_is_not_an_invoice')}</Paragraph>
      </CenterContainer>
    </Section>
  );

  const renderDeliveryQrCode = () => {
    if (order?.method !== ORDER_METHODS.delivery || order?.delivery_provider !== DeliveryProvider.Standalone) {
      return null;
    }

    const url = getGoogleMapsUrl({
      address: `${order?.delivery_street_name} ${order?.delivery_street_number}`,
      zip_code: order?.delivery_zip_code,
      city: order?.delivery_city,
      country: company?.country,
    });

    return url && (
      <Section borderTop>
        <QrCodeContainer>
          <QRCode
            size={pageWidth * 0.4}
            value={url}
          />
        </QrCodeContainer>
        <Paragraph center noMargin>{t('delivery_qr_code.scan')}</Paragraph>
      </Section>
    );
  };

  return (
    <Container
      id={INNER_PRINTER_RECEIPT_ELEMENT_ID}
      style={{
        zIndex: 0,
        width: pageWidth,
        left: -pageWidth,
      }}
    >
      {renderLogo()}
      {renderCustomerInfo()}
      {renderScheduleInfo()}
      {renderProducts()}
      {renderPaymentBreakDown()}
      {renderDeliveryQrCode()}
    </Container>
  );
}

function LabelValue({ children, containerProps }) {
  return (
    <LabelValueContainer {...containerProps}>
      <LabelContainer>
        {children[0]}
      </LabelContainer>
      <ValueContainer>
        {children[1]}
      </ValueContainer>
    </LabelValueContainer>
  );
}

const Container = tw.div`bottom-0 fixed pt-[50px] pb-[130px]`;

const LogoContainer = tw.div`flex items-center justify-center`;

const Section = styled.div(({ borderTop, borderBottom }) => [
  tw`py-4 border-black border-solid border-[5px] border-l-0 border-r-0`,
  !borderTop && tw`border-t-0`,
  !borderBottom && tw`border-b-0`,
]);

const ProductContainer = styled.div(({ noMargin, underline }) => [
  tw`pb-4 mb-4`,
  noMargin && tw`pb-0 mb-0`,
  underline && tw`border-b border-t-0 border-l-0 border-r-0 border-solid`,
]);

const ModifierGroupContainer = tw.div`pl-2`;

const ModifierContainer = tw.div`pl-2`;

const LabelValueContainer = styled.div(({ underline, bottomMargin }) => [
  tw`flex justify-between`,
  underline && tw`border-b border-t-0 border-l-0 border-r-0 border-solid`,
  bottomMargin && tw`mb-2`,
]);

const LabelContainer = tw.div`pr-2 flex-shrink`;

const ValueContainer = tw.div`flex-none`;

const DeliveryInfoContainer = tw.div`flex flex-col`;

const DeliveryInstructionContainer = tw.div`mt-2`;

const QrCodeContainer = tw.div`mb-2 flex items-center justify-center`;

const Row = tw.div`flex`;

const H2 = styled.h2(({
  noMargin, negativeOffset, center, regular,
}) => [
  tw`pb-0 text-black font-bold text-[34px]`,
  noMargin && tw`mb-0`,
  negativeOffset && tw`mb-[-6px]`,
  center && tw`text-center`,
  regular && tw`font-medium`,
]);

const H3 = styled.h3(({
  noMargin, center, noUnderline, regular, className,
}) => [
  tw`mb-2 pb-0 text-black text-[28px]
  font-bold border-l-0 border-r-0 border-t-0 border-solid inline-block`,
  noMargin && tw`mb-0`,
  center && tw`text-center`,
  noUnderline && tw`border-b-0`,
  regular && tw`font-normal`,
  className,
]);

const Paragraph = styled.p(({
  noMargin,
  center,
  bold,
  italic,
  shrink,
  noShrink,
  rightPadding,
  big,
}) => [
  tw`mb-0 pb-0 text-black text-xl font-medium`,
  noMargin && tw`mb-0`,
  center && tw`text-center`,
  bold && tw`font-bold`,
  italic && tw`italic`,
  shrink && tw`flex-shrink`,
  noShrink && tw`flex-none`,
  rightPadding && tw`pr-2`,
  big && tw`text-[22px]`,
]);

const ConfirmTime = tw.p`my-0 py-0 text-center text-3xl text-black italic`;

const Bold = tw.span`my-0 py-0 font-bold`;

const DesiredTime = tw.p`my-0 py-0 text-center text-3xl text-black italic`;

const ScheduleDate = tw.p`my-0 py-0 text-center text-3xl font-bold text-black italic`;

const CenterContainer = tw.div`flex flex-col justify-center items-center`;
