export default {
  text: {
    order_cancelled: 'Bestellung storniert - Fahrer nicht verfügbar',
    wolt_support: 'Fragen? Ruf direkt Wolt an <underline>{{phone_number}}</underline>',
  },
  labels: {
    dummy_order: 'Test Bestellung',
    product_one: 'Produkt',
    product_other: 'Produkte',
  },
  buttons: {
    complete_order: 'Als erledigt markieren',
    looking_for_driver: 'Suchen nach Fahrer...',
    click_to_remove: 'Anklicken zum Entfernen',
  },
};
