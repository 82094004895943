import React from 'react';
import classnames from 'classnames';

export default function CarrierIcon({
  className,
  color,
}) {
  return (
    <svg
      className={classnames('h-6 w-6', className)}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="12" fill="#69CE8C" />
      <path
        d="M6.14729 19.4108L8.12376 9.4579L6.85317 9.95202V12.352H5.44141V9.03438L9.00611 7.51673C9.34729 7.37555 9.69435 7.36085 10.0473 7.47261C10.4002 7.58438 10.6767 7.79908 10.8767 8.11673L11.5826 9.24614C11.9002 9.75202 12.3208 10.1608 12.8443 10.4726C13.3679 10.7844 13.9591 10.9403 14.6179 10.9403V12.352C13.8414 12.352 13.1149 12.1903 12.4385 11.8667C11.762 11.5432 11.1943 11.1167 10.7355 10.5873L10.312 12.705L11.7943 14.1167V19.4108H10.3826V15.1756L8.90023 13.7638L7.62964 19.4108H6.14729ZM10.7355 7.0579C10.3473 7.0579 10.0149 6.91967 9.73846 6.6432C9.46199 6.36673 9.32376 6.03438 9.32376 5.64614C9.32376 5.2579 9.46199 4.92555 9.73846 4.64908C10.0149 4.37261 10.3473 4.23438 10.7355 4.23438C11.1238 4.23438 11.4561 4.37261 11.7326 4.64908C12.0091 4.92555 12.1473 5.2579 12.1473 5.64614C12.1473 6.03438 12.0091 6.36673 11.7326 6.6432C11.4561 6.91967 11.1238 7.0579 10.7355 7.0579ZM17.7943 19.4108L17.0532 18.6697L17.5473 18.1756H13.912V17.1167H17.5473L17.0532 16.6226L17.7943 15.8814L19.5591 17.6461L17.7943 19.4108ZM15.6767 16.4108L13.912 14.6461L15.6767 12.8814L16.4179 13.6226L15.9238 14.1167H19.5591V15.1756H15.9238L16.4179 15.6697L15.6767 16.4108Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
