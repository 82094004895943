export default {
  header: 'Umsatz heute: {{revenue}}',
  subheaders: {
    scheduled_orders: 'Geplante anzeigen ({{count}})',
    new_orders: 'Noch nicht bestätigt ({{count}})',
    ongoing_orders: 'Laufende ({{count}})',
  },
  no_internet: {
    title: 'Keine Internetverbindung',
    text: 'Sie verlieren im Moment Bestellungen und Geld.  Bitte überprüfen Sie das Internet. Versuchen Sie,  das Wifi aus- und wieder einzuschalten.',
    restored: 'Sie sind wieder online',
  },
  error_messages: {
    failed_to_fetch_orders: 'Die Daten konnten nicht geladen werden. Bitte klicke auf das Symbol “Aktualisieren” oder versuche es in 1 Minute erneut.',
    something_went_wrong: 'Etwas ist schief gelaufen, bitte versuche es noch einmal.',
  },
};
