import MultiStepOrderConfirmation from './MultiStepOrderConfirmation';
import OrderConfirmation from './OrderConfirmation';
import OrderTimeChange from './OrderTimeChange';
import OldOrder from './OldOrder';
import ScheduledOrderStartPreparation from './ScheduledOrderStartPreparation';
import SingleStepOrderConfirmation from './SingleStepOrderConfirmation';

export default {
  MultiStepOrderConfirmation,
  OrderConfirmation,
  OrderTimeChange,
  OldOrder,
  ScheduledOrderStartPreparation,
  SingleStepOrderConfirmation,
};
