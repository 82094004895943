import BackendApiClient from './BackendApiClient';

class PrintOrderManager {
  print = (id) => BackendApiClient.requestAuthorized({
    method: 'GET',
    url: `/staff/print-order/${id}`,
  });
}

export default new PrintOrderManager();
