import { CNavItem } from '@coreui/react';
import i18next from 'i18next';
import { ROUTES } from './services/exports/Constants';
import WebViewCommunicatorResource from './services/resources/WebViewCommunicatorResource';

export default function getNavItems(company) {
  return [
    {
      component: CNavItem,
      name: i18next.t('Nav:active_orders.title'),
      to: ROUTES.home,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:order_history.title'),
      to: ROUTES.order_history,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:menu.title'),
      to: ROUTES.menu,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:printer_settings.title'),
      to: ROUTES.printer_settings,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:order_control.title'),
      to: ROUTES.order_control,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:busy_mode.title', { time: company.busy_mode_minutes, count: company.busy_mode_minutes ?? 0 }),
      to: ROUTES.busyMode,
    },
    {
      component: CNavItem,
      name: i18next.t('Nav:volume.title'),
      onClick: () => WebViewCommunicatorResource.navigate({ screen: 'VolumeSettingsScreen' }),
    },
  ];
}
