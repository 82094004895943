import { ORDER_REJECTION_REASONS } from '../../../../../services/exports/Constants';

export default {
  header: 'Bestellung stornieren',
  title: 'Niemals zu leichtfertig ablehnen!',
  text: 'If there’s a minor issue with the order, please call the customer and work it out.',
  reasons: {
    [ORDER_REJECTION_REASONS.ItemUnavailable]: 'Artikel nicht auf Lager',
    [ORDER_REJECTION_REASONS.ClosingSoon]: 'Geschäft ist geschlossen',
    [ORDER_REJECTION_REASONS.StoryBusy]: 'Das Geschäft ist zu sehr beschäftigt',
    [ORDER_REJECTION_REASONS.CustomerMistake]: 'Kunde ruft an, um zu stornieren',
    [ORDER_REJECTION_REASONS.DriverNotArrived]: 'Fahrer nicht angekommen',
  },
  buttons: {
    confirm: 'Bestätigen',
  },
};
