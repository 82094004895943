import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { ROUTES } from '../services/exports/Constants';

export default function AuthMiddleware({ children }) {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    !isLoggedIn && window.location.replace(ROUTES.login);
  }, [isLoggedIn]);

  return isLoggedIn ? children : null;
}
