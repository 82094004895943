import React, { useContext, useState } from 'react';
import { useScreenshot } from 'use-react-screenshot';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrintContext } from '../contexts/PrintContext';
import PrinterIcon from '../resources/assets/icons/PrinterIcon';
import WebViewCommunicatorResource from '../services/resources/WebViewCommunicatorResource';
import { INNER_PRINTER_RECEIPT_ELEMENT_ID, RECEIPT_TYPE } from '../services/exports/Constants';
import PrintOrderManager from '../services/api/PrintOrderManager';

export default function usePrinter() {
  const { t } = useTranslation(null, { keyPrefix: 'Hooks:usePrinter' });

  const company = useSelector((state) => state?.company?.company);
  const { print_copies } = company;
  const { innerPrinterConnected } = useSelector((state) => state.print);
  const { setData } = useContext(PrintContext);
  const [printing, setPrinting] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [innerPrinterReceipt, takeScreenshot] = useScreenshot();

  const getInnerPrinterReceipt = () => takeScreenshot(
    document.getElementById(INNER_PRINTER_RECEIPT_ELEMENT_ID),
  );

  function throwPrintingToast(data) {
    const { type, payload } = data;
    switch (type) {
      case RECEIPT_TYPE.ORDER:
        return (
          toast(
            <div className="d-flex">
              <PrinterIcon
                color="#1C1D28"
                className="printer-icon mr-2"
              />
              <div className="website-text">
                <Trans
                  t={t}
                  values={{
                    count: parseInt(print_copies, 10),
                    orderNumber: payload?.number,
                  }}
                >
                  toasts.orders.print
                </Trans>
              </div>
            </div>,
          )
        );
      default:
        return (
          toast(
            <div className="d-flex">
              <PrinterIcon
                color="#1C1D28"
                className="printer-icon mr-2"
              />
              <div className="website-text">
                {t('toasts.printing')}
              </div>
            </div>,
          )
        );
    }
  }

  async function print(data, printOnTerminalOnly = false) {
    if (printOnTerminalOnly && !innerPrinterConnected) {
      setPrinting(true);
      await PrintOrderManager.print(data.payload.id);
      setPrinting(false);
      return;
    }

    setPrinting(true);
    throwPrintingToast(data);
    setTimeout(() => {
      new Promise((resolve) => {
        setData(data);
        resolve(true);
      }).then(() => {
        getInnerPrinterReceipt().then((receipt) => {
          WebViewCommunicatorResource.printOrder({
            uri: receipt,
            copies: company.print_copies,
          });
          printPrepSuccess();
        }).catch(printPrepError);
      });
    });
  }

  function printPrepSuccess() {
    setPrinting(false);
    setData(null);
  }

  function printPrepError() {
    setPrinting(false);
    setData(null);
    toast(t('toasts.print_failed'));
  }

  return {
    print,
    printing,
    setPrinting,
  };
}
