import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'print',
  initialState: {
    innerPrinterConnected: false,
  },
  reducers: {
    setInnerPrinterConnected: (state, action) => {
      state.innerPrinterConnected = action.payload;
    },
  },
});

export const printProps = (state) => state;
