import * as React from 'react';

export default function CheckCircleIcon({
  className,
  color,
}) {
  return (
    <svg
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-4 w-4'}
    >
      <path
        d="M9.64844 17.6172C14.1797 17.6172 17.8984 13.8906 17.8984 9.35938C17.8984 4.83594 14.1719 1.10938 9.64844 1.10938C5.11719 1.10938 1.39844 4.83594 1.39844 9.35938C1.39844 13.8906 5.125 17.6172 9.64844 17.6172ZM8.78906 13.2812C8.4375 13.2812 8.16406 13.125 7.91406 12.8203L6.07812 10.6328C5.89844 10.4141 5.82812 10.2188 5.82812 9.99219C5.82812 9.50781 6.21875 9.125 6.69531 9.125C6.97656 9.125 7.19531 9.23438 7.40625 9.48438L8.76562 11.1641L11.8125 6.32031C12.0156 5.99219 12.2734 5.82812 12.5781 5.82812C13.0469 5.82812 13.4766 6.17188 13.4766 6.65625C13.4766 6.85156 13.3984 7.0625 13.2578 7.26562L9.63281 12.8125C9.42969 13.1094 9.125 13.2812 8.78906 13.2812Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
