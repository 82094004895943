export default {
  header: 'Busy mode',
  subheader: 'How much time to add to your regular preparation time?',
  busy_mode_subheader: 'Stop busy mode/ change minutes',
  options: {
    '10_min': '10 min',
    '15_min': '15 min',
    '20_min': '20 min',
    '25_min': '25 min',
    '30_min': '30 min',
    '45_min': '45 min',
    '1_hour': '1 hour',
  },
  buttons: {
    save: 'Save',
  },
};
