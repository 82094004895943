import qs from 'qs';
import BackendApiClient from './BackendApiClient';

class OrdersManager {
  get = (params) => BackendApiClient.requestAuthorized({
    method: 'GET',
    url: '/staff/orders',
    params,
    paramsSerializer: qs.stringify,
  });

  show = (id) => BackendApiClient.requestAuthorized({
    method: 'GET',
    url: `/staff/orders/${id}`,
  });

  reject = (id, data) => BackendApiClient.requestAuthorized({
    method: 'PUT',
    url: `/staff/orders/${id}/reject`,
    data,
  });
}

export default new OrdersManager();
