export default {
  filters: {
    today: 'Heute',
    yesterday: 'Gestern',
    this_week: 'Diese Woche',
    last_week: 'Letzte Woche',
    this_month: 'Dieser Monat',
    last_month: 'Letzer Monat',
  },
};
