import { ORDER_REJECTION_REASONS } from '../../../../../services/exports/Constants';

export default {
  header: 'Cancel order',
  title: 'Never reject too lightly!',
  text: 'If there’s a minor issue with the order, please call the customer and work it out.',
  reasons: {
    [ORDER_REJECTION_REASONS.ItemUnavailable]: 'Items out of stock',
    [ORDER_REJECTION_REASONS.ClosingSoon]: 'Store is closed',
    [ORDER_REJECTION_REASONS.StoryBusy]: 'Store is too busy',
    [ORDER_REJECTION_REASONS.CustomerMistake]: 'Customer called to cancel',
    [ORDER_REJECTION_REASONS.DriverNotArrived]: 'Driver not arrived',
  },
  buttons: {
    confirm: 'Confirm',
  },
};
