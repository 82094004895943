import ConnectInnerPrinterHandler from './handlers/ConnectInnerPrinterHandler';

export default class RNWebviewResolver {
  data = {
    type: '',
    payload: null,
  };

  constructor(data) {
    this.data = data;
  }

  resolve() {
    switch (this?.data?.type) {
      case ConnectInnerPrinterHandler.type:
        new ConnectInnerPrinterHandler(this.data?.payload).notify();
        break;
      default:
        break;
    }
  }
}
