import { ORDER_REJECTION_REASONS } from '../../../../../../../services/exports/Constants';

export default {
  statuses: {
    rejected: 'Canceled: {{reason}}',
    fulfilled: 'This order has been successfully fulfilled',
  },
  rejection_reasons: {
    [ORDER_REJECTION_REASONS.Automatic]: 'Not accepted',
    [ORDER_REJECTION_REASONS.ClosingSoon]: 'Closing soon',
    [ORDER_REJECTION_REASONS.ItemUnavailable]: 'Item unavailable',
    [ORDER_REJECTION_REASONS.OrderTooBig]: 'Order too big',
    [ORDER_REJECTION_REASONS.CustomerMistake]: 'Customer mistake',
    [ORDER_REJECTION_REASONS.Other]: 'Other',
    fully_refunded: 'Order was completely refunded',
  },
};
