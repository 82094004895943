import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'appHeader',
  initialState: {
    text: null,
    showRefresh: false,
  },
  reducers: {
    set: (state, action) => {
      state.text = action.payload?.text ?? state.text;
      state.showRefresh = action.payload?.showRefresh ?? state.showRefresh;
    },
    reset: (state) => {
      state.text = null;
      state.showRefresh = false;
    },
  },
});

export const appHeaderProps = (state) => state;
