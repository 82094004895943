import Dev from './Dev.png';
import Staging from './Staging.png';
import Prod from './Prod.png';
import { APP_ENVIRONMENTS } from '../../../../services/exports/Constants';

function getIcon() {
  switch (import.meta.env.VITE_MODE) {
    case APP_ENVIRONMENTS.DEV:
      return Dev;
    case APP_ENVIRONMENTS.STAGING:
      return Staging;
    default:
      return Prod;
  }
}

export const Favicon = getIcon();
