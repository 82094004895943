import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

function ScheduledOrdersFlicker({
  ordersCount,
  onClick,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Flickers:ScheduledOrdersFlicker' });

  return (
    <Container onClick={onClick}>
      <Wrapper>
        <CounterCircle>
          <CounterText>{ordersCount}</CounterText>
        </CounterCircle>
        <CounterLabelText>{t('header', { count: ordersCount })}</CounterLabelText>
        <HelperText>{t('text')}</HelperText>
      </Wrapper>
    </Container>
  );
}

export default ScheduledOrdersFlicker;

const Container = tw.div`z-1200 h-screen w-screen bg-lightblue fixed inset-0 flex justify-center cursor-pointer p-4`;
const Wrapper = tw.div`flex-col m-auto`;
const CounterCircle = tw.div`h-80 w-80 rounded-full bg-blue m-auto flex`;
const CounterText = tw.h1`m-auto text-white`;
const CounterLabelText = tw.h2`m-auto text-white mt-8 text-center text-3xl font-semibold`;
const HelperText = tw.h3`m-auto mt-2 text-center text-white text-lg font-normal`;
