import React from 'react';
import OrderConfirmation from './OrderConfirmation';
import { FULFILLMENT_STATUSES } from '../../../../services/exports/Constants';
import ScheduledOrderStartPreparation from './ScheduledOrderStartPreparation';
import OrderTimeChange from './OrderTimeChange';
import OldOrder from './OldOrder';

export default function Footer(props) {
  const { order } = props;

  const orderConfirmation = <OrderConfirmation {...props} />;
  const scheduledOrderStartPreparation = <ScheduledOrderStartPreparation {...props} />;
  const orderTimeChange = <OrderTimeChange {...props} />;
  const oldOrder = <OldOrder {...props} />;

  if (!order.is_manually_confirmed) {
    return orderConfirmation;
  }

  return ({
    [FULFILLMENT_STATUSES.created]: orderConfirmation,
    [FULFILLMENT_STATUSES.reviewed]: orderConfirmation,
    [FULFILLMENT_STATUSES.accepted]: order.scheduled_for ? scheduledOrderStartPreparation : orderTimeChange,
    [FULFILLMENT_STATUSES.preparing]: orderTimeChange,
    [FULFILLMENT_STATUSES.ready]: orderTimeChange,
    [FULFILLMENT_STATUSES.on_delivery]: orderTimeChange,
  })[order.fulfillment_status] ?? oldOrder;
}
