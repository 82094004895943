export default function useMaps() {
  function getGoogleMapsUrl(data) {
    const query = `${data?.address}, ${data?.zip_code} ${data?.city}, ${data?.country}`;

    const encodedQuery = query.replace('|', '%7C')?.replace(',', '%2C')?.replace(' ', '+');

    return `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`;
  }

  function openGoogleMaps(data) {
    try {
      window.open(getGoogleMapsUrl(data));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  return {
    getGoogleMapsUrl,
    openGoogleMaps,
  };
}
