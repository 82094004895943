import React from 'react';
import Lottie from 'react-lottie-player';
import { colorify } from 'lottie-colorify';
import LoadingAnimation from '../../resources/assets/animations/LoadingAnimation.json';

export default function LoadingIndicator({
  src = LoadingAnimation,
  loop = true,
  autoplay = true,
  width = 50,
  height = 50,
  style = {},
  color = null,
  className = 'm-auto',
}) {
  return (
    <Lottie
      play={autoplay}
      loop={loop}
      animationData={color ? colorify([color], src) : src}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      className={className}
      style={{
        ...style,
        height,
        width,
      }}
    />
  );
}
