import React from 'react';
import classnames from 'classnames';
import { DeliveryProvider, ORDER_METHODS, ROOM_SERVICE_LOCATION_TYPES } from '../../services/exports/Constants';
import DeliveryProviderIcon from '../delivery/DeliveryProviderIcon';
import RoomServiceIcon from '../../resources/assets/icons/RoomServiceIcon';
import TableServiceIcon from '../../resources/assets/icons/TableServiceIcon';
import PickupIcon from '../../resources/assets/icons/PickupIcon';
import DeliveryIcon from '../../resources/assets/icons/DeliveryIcon';

export const Sizes = {
  sm: 'sm',
  lg: 'lg',
};

export default function OrderMethodIcon({
  order,
  className,
  size = Sizes.lg,
}) {
  if (order.method === ORDER_METHODS.delivery && order.delivery_provider !== DeliveryProvider.Standalone) {
    const sizeClasses = ({
      [Sizes.sm]: 'h-10 w-10',
      [Sizes.lg]: 'h-12 w-12',
    })[size] ?? null;

    return (
      <DeliveryProviderIcon
        provider={order.delivery_provider}
        className={classnames(sizeClasses, className)}
      />
    );
  }
  const iconSizeClasses = ({
    [Sizes.sm]: ({
      [ORDER_METHODS.room_service]: 'h-6 w-6',
      [ORDER_METHODS.pickup]: 'h-9 w-9',
      [ORDER_METHODS.delivery]: 'h-8 w-8',
    })[order.method] ?? null,
    [Sizes.lg]: ({
      [ORDER_METHODS.room_service]: 'h-8 w-8',
      [ORDER_METHODS.pickup]: 'h-12 w-12',
      [ORDER_METHODS.delivery]: 'h-12 w-12',
    })[order.method] ?? null,
  })[size] ?? null;

  const containerSizeClasses = ({
    [Sizes.sm]: 'h-10 w-10',
    [Sizes.lg]: 'h-12 w-12',
  })[size] ?? null;

  const icon = {
    [ORDER_METHODS.room_service]: order.room_service_location?.type === ROOM_SERVICE_LOCATION_TYPES.room_service
      ? <RoomServiceIcon className={classnames('m-auto', iconSizeClasses, className)} />
      : <TableServiceIcon className={classnames('m-auto', iconSizeClasses, className)} />,
    [ORDER_METHODS.pickup]: <PickupIcon className={classnames('m-auto', iconSizeClasses, className)} />,
    [ORDER_METHODS.delivery]: <DeliveryIcon className={classnames('m-auto', iconSizeClasses, className)} />,
  }[order.method];

  return (
    <div className={classnames('bg-gray-800 tw-rounded-full tw-flex', containerSizeClasses, className)}>
      {icon}
    </div>
  );
}
