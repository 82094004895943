import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useHelpers from '../../../../hooks/useHelpers';
import Button, { Types, Variants } from '../../../buttons/Button';
import { DeliveryProvider, ORDER_METHODS } from '../../../../services/exports/Constants';
import PrimaryOrderButton from '../../../buttons/PrimaryOrderButton';
import PrimaryOrderBadge from '../../../badge/PrimaryOrderBadge';

export default function SingleStepOrderConfirmation({
  order,
  preparationTime,
  setPreparationTime,
  pickupTime,
  minutesFromNow,
  orderMethod,
  isOrderAcceptable,
  isToday,
  loading,
  printing,
  acceptOrder,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:SingleStepOrderConfirmation' });

  const { company } = useSelector((state) => state.company);

  const { formatTime } = useHelpers();

  const step = company?.order_approval_time_step ?? 10;
  const isInitialStep = preparationTime - step <= 0;
  const isExternalDelivery = order?.method === ORDER_METHODS.delivery && order?.delivery_provider !== DeliveryProvider.Standalone;

  return (
    <>
      <h5 className="w-full text-center">{t(`title.${order.method}`)}</h5>
      <p className="w-full text-center">
        {
          !order.scheduled_for
            ? t('text', {
              method: orderMethod,
              time: minutesFromNow,
            })
            : t('text_scheduled', {
              method: orderMethod,
              day: isToday ? t('labels.today') : pickupTime.format('dddd, DD MMM'),
              time: formatTime(pickupTime),
            })
        }
      </p>
      <CRow className="w-100 mt-4 sm-mt-2">
        <CCol xs={4} className="pl-8 sm-pl-0">
          {!isExternalDelivery && (
            <Button
              title={(
                <>
                  <b>-</b>
                  <br />
                  {step}
                  {' '}
                  min
                </>
              )}
              disabled={isInitialStep || (order.scheduled_for && preparationTime <= order.estimated_preparation_time)}
              onClick={() => setPreparationTime((current) => current - step)}
              className="w-100 h-100"
              type={Types.primary}
              variant={Variants.outline}
            />
          )}
        </CCol>
        <CCol xs={4} className="text-center sm-p-0">
          {isToday && (
            <PrimaryOrderBadge order={order} className="tw-mx-auto">
              {minutesFromNow}
              {' '}
              min
            </PrimaryOrderBadge>
          )}
          <div className="website-text bold mt-1">
            {isToday ? t('labels.today') : pickupTime.format('dddd, DD MMM')}
          </div>
          <div className="website-text bold mt-1">
            {formatTime(pickupTime)}
          </div>
        </CCol>
        <CCol xs={4} className="pr-8 sm-pr-0">
          {!isExternalDelivery && (
            <Button
              title={(
                <>
                  <b>+</b>
                  <br />
                  {step}
                  {' '}
                  min
                </>
              )}
              onClick={() => setPreparationTime((current) => current + step)}
              className="w-100 h-100"
              type={Types.primary}
              variant={Variants.outline}
            />
          )}
        </CCol>
      </CRow>
      <PrimaryOrderButton
        order={order}
        title={t('buttons.confirm')}
        disabled={!isOrderAcceptable}
        onClick={acceptOrder}
        loading={loading || printing}
        className="tw-w-full tw-mt-small"
      />
    </>
  );
}
