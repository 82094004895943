export default {
  toasts: {
    printing: 'Printing...',
    print_failed: 'Printing failed. Please try again',
    orders: {
      print_one: 'Printing {{count}} receipt: Order <strong>#{{orderNumber}}</strong>...',
      print_other: 'Printing {{count}} receipts: Order <strong>#{{orderNumber}}</strong>...',
    },
  },
};
