import { DeliveryMode, DeliveryProvider } from '../../../../../services/exports/Constants';

export default {
  header: 'Pause new orders',
  sections: {
    pause_orders: {
      title: 'How long will you be unavailable?',
      description: 'We’ll inform customers that you’re currently not taking new orders',
    },
    order_methods: {
      title: 'My order methods',
      description: 'Turn on or off methods for your customers to order from you',
    },
    delivery_mode: {
      title: 'Delivery methods',
      description: 'Switch between your and our driver network',
    },
  },
  form: {
    orders_paused_until: {
      options: {
        '10_min': '10 min',
        '15_min': '15 min',
        '20_min': '20 min',
        '25_min': '25 min',
        '1_hour': '1 hour',
        '2_hours': '2 hours',
        rest_of_day: 'Rest of the day',
      },
    },
    has_standalone_delivery: {
      label: 'My drivers',
    },
    has_external_delivery: {
      label: '{{provider}} driver network',
    },
  },
  labels: {
    delivery_orders_off: 'Delivery orders off',
    delivery_orders_on: 'Delivery orders on',
  },
  modals: {
    toggle_delivery: {
      [true]: {
        title: 'Are you sure you want to enable delivery orders?',
        description: '',
      },
      [false]: {
        title: 'Are you sure you want to disable delivery orders?',
        description: '',
      },
    },
    switch_delivery_mode: {
      [DeliveryMode.Internal]: {
        title: 'Are you sure you want to switch to your drivers?',
        description: '',
      },
      [DeliveryMode.External]: {
        title: 'Are you sure you want to switch to {{provider}} drivers?',
        description: '',
      },
    },
  },
  buttons: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes saved successfully',
  },
};
