import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Countdown, { zeroPad } from 'react-countdown';

export default function PendingOrdersFlicker({
  orders,
  onClick,
  onTimerEnd,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Flickers:PendingOrdersFlicker' });

  const oldestOrder = orders?.last();

  const renderer = ({ minutes, seconds }) => (
    <span>
      {zeroPad(minutes)}
      :
      {zeroPad(seconds)}
    </span>
  );

  const renderCountdown = useMemo(() => oldestOrder && (
    <Countdown
      key={oldestOrder.id}
      date={moment(oldestOrder?.auto_rejects_at).toDate()}
      onComplete={() => onTimerEnd(oldestOrder)}
      renderer={renderer}
    />
  ), [oldestOrder]);

  return (
    <Container onClick={onClick}>
      <Wrapper>
        <CounterCircle>
          <CounterText>{orders?.count()}</CounterText>
        </CounterCircle>
        <CounterLabelText>{t('header')}</CounterLabelText>
        <HelperText>{t('text')}</HelperText>
        <CounterLabelText>{renderCountdown}</CounterLabelText>
      </Wrapper>
    </Container>
  );
}

const Container = tw.div`z-1200 h-screen w-screen bg-yellow fixed inset-0 flex justify-center cursor-pointer p-4`;
const Wrapper = tw.div`flex-col m-auto`;
const CounterCircle = tw.div`h-80 w-80 rounded-full bg-yellow-400 m-auto flex`;
const CounterText = tw.h1`m-auto text-white`;
const CounterLabelText = tw.h2`m-auto mt-3 text-center text-3xl font-semibold`;
const HelperText = tw.h3`m-auto mt-2 text-center text-sm leading-7 font-normal`;
