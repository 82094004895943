export default {
  header: 'Menü',
  tabs: {
    menu: 'Menü',
    modifier_groups: 'Modifizierer',
  },
  toasts: {
    failed_to_load_data: 'Das Menü konnte nicht geladen werden. Bitte aktualisieren und erneut versuchen',
  },
};
