import ActiveOrders from './active-orders';
import Alerts from './alerts';
import Auth from './auth';
import BusyMode from './busy-mode';
import Error from './error';
import Menu from './menu';
import OrderControl from './order-control';
import OrderHistory from './order-history';
import PrinterSettings from './printer-settings';

export default {
  ActiveOrders,
  Alerts,
  Auth,
  BusyMode,
  Error,
  Menu,
  OrderControl,
  OrderHistory,
  PrinterSettings,
};
