import React, { Suspense } from 'react';
import {
  BrowserRouter as BaseRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import routes from './routes';
import AuthMiddleware from './AuthMiddleware';
import Layout from '../views/Layout';
import { ROUTES } from '../services/exports/Constants';

export default function Router() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );

  return (
    <BaseRouter>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => (
            route.component && (
              <Route
                key={`route-${idx}`}
                path={route.path}
                exact={route.exact}
                render={(props) => (route.is_private ? (
                  <AuthMiddleware>
                    <Layout>
                      <route.component {...props} />
                    </Layout>
                  </AuthMiddleware>
                ) : <route.component {...props} />)}
              />
            )
          ))}
          <Redirect to={ROUTES.login} />
        </Switch>
      </Suspense>
    </BaseRouter>
  );
}
