import CloseOrder from './close-order';
import Delivery from './delivery';
import OrderDetails from './order-details';
import CreateRefundModal from './CreateRefundModal';
import RejectOrderModal from './RejectOrderModal';

export default {
  CloseOrder,
  Delivery,
  OrderDetails,
  CreateRefundModal,
  RejectOrderModal,
};
