import React from 'react';
import tw from 'twin.macro';

export default function Footer({ children }) {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = tw.div`sticky bottom-0 z-50`;
