import React from 'react';
import Button, { Types } from './Button';
import theme from '../../resources/theme';

export default function PrimaryOrderButton({ order, ...rest }) {
  const type = !order.is_pickup_time_optimized ? Types.custom : Types.primary;
  const color = !order.is_pickup_time_optimized ? theme.colors.purple : null;

  const _props = {
    type,
    color,
    ...rest,
  };

  return <Button {..._props} />;
}
