export default {
  text: {
    driver_found: 'Driver found - Pickup at {{time}}',
    looking_for_driver: 'We are looking for the next driver for you',
    receipt: 'The receipt will be printed automatically when we find a driver',
  },
  buttons: {
    ok: 'Okay',
  },
};
