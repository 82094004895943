import {
  FULFILLMENT_STATUSES,
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../services/exports/Constants';

export default {
  header: 'Bestellhistorie',
  labels: {
    dummy_order: 'Test Bestellung',
    orders_number: 'Anzahl der Bestellungen ({{count}})',
    revenue: 'Umsatz: {{amount}}',
    tips: 'Trinkgeld: {{amount}}',
    cash_sales: ' ({{amount}} Bargeld)',
    rejected_orders: 'Erstattungen: {{count}} ({{amount}})',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ORDER_METHODS.room_service]: {
      [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
      [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
    },
  },
  statuses: {
    [FULFILLMENT_STATUSES.created]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.reviewed]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.accepted]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.preparing]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.ready]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.on_delivery]: 'In Bearbeitung',
    [FULFILLMENT_STATUSES.picked]: 'Abgeschlossen',
    [FULFILLMENT_STATUSES.rejected]: 'Storniert',
  },
  payment_methods: {
    cash: 'Bargeld',
    online: 'Online-Methode',
  },
  filters: {
    all: 'Alle',
  },
  table: {
    headers: {
      order_placed: 'DATUM',
      order_number: '#',
      customer: 'Kunde',
      payment_method: 'Zahlungsmethode',
      method: 'Typ',
      status: 'Status',
      total: 'GESAMT',
    },
  },
  buttons: {
    print: 'Drucken',
    create_test_order: 'Testbestellung erstellen',
  },
  toasts: {
    failed_to_fetch_orders: 'Die Daten konnten nicht geladen werden. Bitte klicke auf das Symbol “Aktualisieren” oder versuche es in 1 Minute erneut.',
    something_went_wrong: 'Etwas ist schief gelaufen, bitte versuche es noch einmal.',
    failed_to_create_test_order: 'Testbestellung konnte nicht erstellt werden',
    test_order_created: 'Testbestellung erstellt',
  },
};
