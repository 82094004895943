export default {
  active_orders: {
    title: 'Ausstehende Bestellungen',
  },
  order_history: {
    title: 'Bestellhistorie',
  },
  menu: {
    title: 'Menü',
  },
  printer_settings: {
    title: 'Drucker Einstellungen',
  },
  order_control: {
    title: 'Bestelloptionen',
  },
  busy_mode: {
    title_zero: 'Busy Mode',
    title_other: 'Busy Mode (+{{time}} min)',
  },
  volume: {
    title: 'Lautstärke',
  },
  switch_language: {
    title: 'Sprache zu Englisch wechseln',
  },
  logout: {
    title: 'Ausloggen',
  },
};
