export default {
  active_orders: {
    title: 'Active orders',
  },
  order_history: {
    title: 'Order History',
  },
  menu: {
    title: 'Menu',
  },
  printer_settings: {
    title: 'Printer settings',
  },
  order_control: {
    title: 'Order Control',
  },
  busy_mode: {
    title_zero: 'Busy mode',
    title_other: 'Busy mode (+{{time}} min)',
  },
  volume: {
    title: 'Volume',
  },
  switch_language: {
    title: 'Switch language to German',
  },
  logout: {
    title: 'Logout',
  },
};
