import AsapOrdersFlicker from './AsapOrdersFlicker';
import ScheduledOrdersFlicker from './ScheduledOrdersFlicker';
import PendingOrdersFlicker from './PendingOrdersFlicker';
import ScheduledOrderReminderFlicker from './ScheduledOrderReminderFlicker';
import OrdersPausedFlicker from './OrdersPausedFlicker';

export default {
  AsapOrdersFlicker,
  ScheduledOrdersFlicker,
  PendingOrdersFlicker,
  ScheduledOrderReminderFlicker,
  OrdersPausedFlicker,
};
