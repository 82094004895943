import React from 'react';
import useEnvironment from '../../hooks/useEnvironment';

export default function EnvironmentBanner() {
  const env = import.meta.env.MODE;
  const { isProduction, isStaging } = useEnvironment();

  function getBackgroundColor() {
    if (isStaging) {
      return '#F89A2A';
    }
    return '#000000';
  }

  return !isProduction && (
    <div
      className="h-8 w-full d-flex justify-content-center"
      style={{ backgroundColor: getBackgroundColor() }}
    >
      <h6 className="my-auto text-white tw-text-sm">{env}</h6>
    </div>
  );
}
