import React from 'react';
import { ROUTES } from '../services/exports/Constants';

export default [
  {
    path: ROUTES.login,
    component: React.lazy(() => import('../views/auth/LoginView')),
  },
  {
    path: ROUTES.home,
    exact: true,
    is_private: true,
    component: React.lazy(() => import('../views/active-orders')),
  },
  {
    path: ROUTES.order_history,
    is_private: true,
    component: React.lazy(() => import('../views/order-history')),
  },
  {
    path: ROUTES.menu,
    is_private: true,
    component: React.lazy(() => import('../views/menu')),
  },
  {
    path: ROUTES.printer_settings,
    is_private: true,
    component: React.lazy(() => import('../views/printer-settings/PrinterSettingsView')),
  },
  {
    path: ROUTES.order_control,
    is_private: true,
    component: React.lazy(() => import('../views/order-control')),
  },
  {
    path: ROUTES.busyMode,
    is_private: true,
    component: React.lazy(() => import('../views/busy-mode/BusyModeView')),
  },
  {
    path: ROUTES.logout,
    is_private: true,
    component: React.lazy(() => import('../views/auth/LogoutView')),
  },
];
