function compilePrivateChannel(name) {
  return `private-${name}`;
}

export function systemChannel() {
  return compilePrivateChannel('staff-system');
}

export function companyChannel(id) {
  return compilePrivateChannel(`company-${id}`);
}
