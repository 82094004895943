export default {
  header: 'Receipt printing',
  settings: 'Settings',
  setup: 'Printer Setup',
  sunmi_printer: 'Sunmi Printer',
  connected_printers: 'Connected Printers',
  labels: {
    auto_print: 'Automatically print receipts',
    print_copies: 'Number of copies',
  },
  buttons: {
    test_print: 'Test Print',
    add_printers: '+ Add Printers',
  },
};
