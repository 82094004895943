import BackendApiClient from './BackendApiClient';

class ProfileManager {
  get = () => BackendApiClient.requestAuthorized({
    method: 'GET',
    url: '/staff/profile',
  });

  update = (data) => BackendApiClient.requestAuthorized({
    method: 'PUT',
    url: '/staff/profile',
    data,
  });

  pauseOrders = (time) => BackendApiClient.requestAuthorized({
    method: 'PUT',
    url: '/staff/pause-orders',
    data: {
      orders_paused_until: time,
    },
  });

  unpauseOrders = () => BackendApiClient.requestAuthorized({
    method: 'PUT',
    url: '/staff/unpause-orders',
  });
}

export default new ProfileManager();
