export default {
  filters: {
    today: 'Today',
    yesterday: 'Yesterday',
    this_week: 'This week',
    last_week: 'Last week',
    this_month: 'This month',
    last_month: 'Last month',
  },
};
