import AsapOrdersFlicker from './AsapOrdersFlicker';
import OrdersPausedFlicker from './OrdersPausedFlicker';
import PendingOrdersFlicker from './PendingOrdersFlicker';
import ScheduledOrderReminderFlicker from './ScheduledOrderReminderFlicker';
import ScheduledOrdersFlicker from './ScheduledOrdersFlicker';

export default {
  AsapOrdersFlicker,
  OrdersPausedFlicker,
  PendingOrdersFlicker,
  ScheduledOrderReminderFlicker,
  ScheduledOrdersFlicker,
};
