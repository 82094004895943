import BackendApiClient from './BackendApiClient';

class RefundsManager {
  create = async (transactionId, data) => BackendApiClient.requestAuthorized({
    method: 'POST',
    url: `/staff/transactions/${transactionId}/refunds`,
    data,
  });
}

export default new RefundsManager();
