export default {
  title: 'Erstattungsbetrag eingeben',
  description: 'Verfügbar für Erstattung: {{amount}}',
  form: {
    amount: {
      label: 'Erstatten',
    },
    reason: {
      label: 'Grund',
      options: {
        requested_by_customer: 'vom Kunden gewünscht',
        reclamation: 'Reklamation',
        communication_error: 'Kommunikationsfehler',
        other: 'andere',
      },
    },
    manual_reason: {
      label: 'Erstattungsgrund eingeben',
    },
  },
  feedback: {
    amount_too_small: 'Mindest-Erstattungsbetrag {{amount}}',
    amount_too_big: 'Maximaler Erstattungsbetrag {{amount}}',
  },
  buttons: {
    refund: 'Erstatten',
    cancel: 'Abbrechen',
  },
};
