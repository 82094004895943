import React, { useMemo, useState } from 'react';
import { CContainer } from '@coreui/react';
import AlertsView from './alerts/AlertsView';
import { Sidebar, Header } from '../components/index';
import Footer from '../components/Footer';
import { FooterContext } from '../contexts/FooterContext';

function Layout({ children }) {
  const [footerChildren, setFooterChildren] = useState(null);

  const footerContext = useMemo(
    () => ({
      setFooterContent: (content) => {
        setFooterChildren(content);
      },
    }),
    [],
  );

  return (
    <div>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <Header />
        <FooterContext.Provider value={footerContext}>
          <div className="body flex-grow-1">
            <CContainer lg className="overflow-x-scroll min-vh-100">
              {children}
            </CContainer>
          </div>
          <Footer>{footerChildren}</Footer>
        </FooterContext.Provider>
      </div>
      <AlertsView />
    </div>
  );
}

export default Layout;
