import React, { useMemo, useState } from 'react';
import { PrintContext } from '../contexts/PrintContext';
import InnerPrinterReceipt from '../components/print/InnerPrinterReceipt';
import InnerPrinterOrderHistoryReceipt from '../components/print/InnerPrinterOrderHistoryReceipt';
import { RECEIPT_TYPE } from '../services/exports/Constants';

export default function PrintProvider({ children }) {
  const [data, setData] = useState(null);

  const context = useMemo(() => ({
    data,
    setData,
  }), [data]);

  const renderReceipt = useMemo(() => {
    switch (data?.type) {
      case RECEIPT_TYPE.ORDER:
        return <InnerPrinterReceipt payload={data?.payload} />
      case RECEIPT_TYPE.ORDER_HISTORY:
        return <InnerPrinterOrderHistoryReceipt payload={data?.payload} />
      default:
        return null;
    }
  }, [data]);

  return (
    <PrintContext.Provider value={context}>
      {renderReceipt}
      {children}
    </PrintContext.Provider>
  );
}
