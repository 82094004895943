export default {
  total_orders: {
    title: 'Bestellungen',
    orders: 'Bestellungen:',
    sales_volume: 'Umsatz:',
    delivery_costs: 'Lieferkosten:',
  },
  cash_orders: {
    title: 'Bezahlung in Bar/ an der Tür',
    orders: 'Bestellungen:',
    sales_volume: 'Umsatz:',
    delivery_costs: 'Lieferkosten:',
  },
  online_orders: {
    title: 'Online-Zahlungen',
    orders: 'Bestellungen:',
    sales_volume: 'Umsatz:',
    delivery_costs: 'Lieferkosten:',
  },
  total: 'Total',
};
