export default {
  header: 'Zubereitungszeit',
  subheader: 'Wie viel Zeit zur normalen Zubereitungszeit hinzufügen?',
  busy_mode_subheader: 'Zurück zu normalen Vorbereitungszeiten',
  options: {
    '10_min': '10 min',
    '15_min': '15 min',
    '20_min': '20 min',
    '25_min': '25 min',
    '30_min': '30 min',
    '45_min': '45 min',
    '1_hour': '1 Stunde',
  },
  buttons: {
    save: 'Speichern',
  },
};
