import React from 'react';
import { CCol, CRow } from '@coreui/react';
import useHelpers from '../../hooks/useHelpers';

export default function Variant({ name, price }) {
  const { formatCurrency } = useHelpers();

  return (
    <CRow className="mt-1">
      <CCol md={1} sm={1} xs={1} />
      <CCol md={9} sm={8} xs={8}>
        <div className="website-text bold">{name}</div>
      </CCol>
      <CCol md={2} sm={3} xs={3}>
        <div className="text-right website-text">
          {formatCurrency(price)}
        </div>
      </CCol>
    </CRow>
  );
}
