export default {
  labels: {
    open: 'Show items',
    collapse: 'Close items',
    in_stock: 'In Stock',
    out_of_stock: 'Out of Stock',
    visible: 'Visible in menu',
    hidden: 'Hidden from menu',
  },
  list: {
    name: 'Menu item',
    price: 'Price',
    is_available: 'IN/OUT OF STOCK',
  },
};
