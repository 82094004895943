import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import EventHelperSlice from './slices/EventHelperSlice';
import SideBarSlice from './slices/SideBarSlice';
import CompanySlice from './slices/CompanySlice';
import ActiveOrders from './slices/ActiveOrdersSlice';
import AppHeaderSlice from './slices/AppHeaderSlice';
import OrdersSlice from './slices/OrdersSlice';
import PrintSlice from './slices/PrintSlice';

const persistConfig = {
  key: 'root',
  storage,
};

export const reducersCombined = combineReducers({
  sidebar: SideBarSlice.reducer,
  appHeader: AppHeaderSlice.reducer,
  eventHelper: EventHelperSlice.reducer,
  company: CompanySlice.reducer,
  activeOrders: ActiveOrders.reducer,
  orders: OrdersSlice.reducer,
  print: PrintSlice.reducer,
});

const reducerPersisted = persistReducer(persistConfig, reducersCombined);

const rootReducer = (state, actions) => {
  if (actions.type === 'RESET_STORE') {
    state = undefined;
  }

  return reducerPersisted(state, actions);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    try {
      return action.type?.startWith('orders/')
        ? null
        : action;
    } catch (e) {
      return null;
    }
  },
  stateTransformer: (state) => ({
    ...state,
    orders: null,
  }),
}));
export const persistor = persistStore(store);
