import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'company',
  initialState: {
    company: {
      id: null,
      name: null,
      address: null,
      city: null,
      country: null,
      phone_number: null,
      orders_paused_from: null,
      orders_paused_until: null,
      orders_pause_reason: null,
      busy_mode_minutes: null,
      average_order_preparation_time: null,
      average_order_delivery_time: null,
      has_delivery: false,
      delivery_enabled: false,
      print_copies: 1,
      auto_print: true,
      is_price_section_hidden_on_receipt: false,
    },
  },
  reducers: {
    set: (state, action) => {
      try {
        state.company = action.payload;
      } catch (error) {
        console.error('Failed to set company', error);
      }
    },
    reset: (state) => {
      state.company = {
        id: null,
        name: null,
        address: null,
        city: null,
        country: null,
        phone_number: null,
        orders_paused_from: null,
        orders_paused_until: null,
        orders_pause_reason: null,
        busy_mode_minutes: null,
        average_order_preparation_time: null,
        average_order_delivery_time: null,
        has_delivery: false,
        delivery_enabled: false,
        print_copies: 1,
        auto_print: true,
      };
    },
    setPrintCopies: (state, action) => {
      state.company.print_copies = action.payload;
    },
    toggleAutoPrint: (state) => {
      state.company.auto_print = !state.company.auto_print;
    },
    toggleDeliveryEnabled: (state) => {
      state.company.delivery_enabled = !state.company.delivery_enabled;
    },
  },
});

export const company = (state) => state;
