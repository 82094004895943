import { DeliveryMode, DeliveryProvider } from '../../../../../services/exports/Constants';

export default {
  header: 'Bestellungen pausieren',
  sections: {
    pause_orders: {
      title: 'Wie lange wirst du nicht verfügbar sein?',
      description: 'Wir werden die Kunden darüber informieren, dass Sie derzeit keine neuen Bestellungen entgegennehmen',
    },
    order_methods: {
      title: 'Meine Bestellmethoden',
      description: 'Schalte die Methoden ein oder aus, mit denen deine Kunden bei dir bestellen können',
    },
    delivery_mode: {
      title: 'Lieferung Methoden',
      description: 'Wechsel zwischen deinen und unserem Fahrer Netzwerk',
    },
  },
  form: {
    orders_paused_until: {
      options: {
        '10_min': '10 min',
        '15_min': '15 min',
        '20_min': '20 min',
        '25_min': '25 min',
        '1_hour': '1 Stunde',
        '2_hours': '2 Stunden',
        rest_of_day: 'Rest des Tages',
      },
    },
    has_standalone_delivery: {
      label: 'Meine Fahrer',
    },
    has_external_delivery: {
      label: '{{provider}} Fahrernetzwerk',
    },
  },
  labels: {
    delivery_orders_off: 'Lieferbestellungen aus',
    delivery_orders_on: 'Lieferbestellungen an',
  },
  modals: {
    toggle_delivery: {
      [true]: {
        title: 'Bist du sicher, dass du Lieferbestellungen aktivieren willst?',
        description: '',
      },
      [false]: {
        title: 'Bist du sicher, dass du Lieferbestellungen ausschalten willst?',
        description: '',
      },
    },
    switch_delivery_mode: {
      [DeliveryMode.Internal]: {
        title: 'Bist du sicher, dass du zu Meine Fahrer wechseln willst?',
        description: '',
      },
      [DeliveryMode.External]: {
        title: 'Bist du sicher, dass du zu {{provider}} Fahrernetzwerk wechseln willst?',
        description: '',
      },
    },
  },
  buttons: {
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
  },
};
