import ReduxHooks from '../../store/ReduxHooks';

const TOKEN_KEY = 'AMIGO_STAFF_API_AUTH_TOKEN';

class AuthorizationTokenStorage {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  async saveToken(token) {
    return new Promise((resolve) => {
      localStorage.setItem(TOKEN_KEY, token);

      resolve(true);
    });
  }

  deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
    ReduxHooks.clearReduxStore();
  }
}

export default new AuthorizationTokenStorage();
