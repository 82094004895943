import React, { useEffect } from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import WebViewCommunicatorResource from '../../services/resources/WebViewCommunicatorResource';
import { WEBVIEW_COMMUNICATOR_COMMANDS } from '../../services/exports/Constants';

export default function ScheduledOrderReminderFlicker({
  orders,
  onClick,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Flickers:ScheduledOrderReminderFlicker' });

  const order = orders.first();

  useEffect(() => {
    WebViewCommunicatorResource.playSound(
      WEBVIEW_COMMUNICATOR_COMMANDS.PLAY_SCHEDULED_ORDER_REMINDER_SOUND,
    );
  }, []);

  return (
    <Container onClick={onClick}>
      <Wrapper>
        <div className="d-flex justify-content-center">
          <div className="d-inline-flex">
            <h4 className="white my-auto mr-2">Nr:</h4>
            <OrderNumber>{order.number}</OrderNumber>
          </div>
        </div>
        <CounterLabelText>{t('header')}</CounterLabelText>
        <HelperText>{t('text')}</HelperText>
      </Wrapper>
    </Container>
  );
}

const Container = tw.div`z-1200 h-screen w-screen bg-orange fixed inset-0 flex justify-center cursor-pointer p-4`;
const Wrapper = tw.div`flex-col m-auto`;
const OrderNumber = tw.div`m-auto flex text-6xl text-white font-semibold`;
const CounterLabelText = tw.h2`m-auto text-white mt-8 text-center text-3xl font-semibold`;
const HelperText = tw.h3`m-auto mt-2 text-center text-white text-lg font-normal`;
