import * as React from 'react';

export default function ChevronLeftIcon({
  className, color,
}) {
  return (
    <svg
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'tw-h-2 tw-w-2'}
    >
      <path d="M0.91602 5L4.74936 2.38419e-07H7.91602L4.08268 5L7.91602 10H4.74936L0.91602 5Z" fill={color ?? '#5F6974'} />
    </svg>
  );
}
