import {
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
  ERROR_CODES,
} from '../../../../../../../services/exports/Constants';

export default {
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
  },
  toasts: {
    [ERROR_CODES.no_delivery_quotes_available]: 'Unfortunately, the delivery needs to be canceled because the driver is not available. Click on the three dots at the top right to cancel the order and refund the money to the customer',
    [ERROR_CODES.delivery_provider_exception]: 'Unfortunately, the delivery needs to be canceled because the driver is not available. Click on the three dots at the top right to cancel the order and refund the money to the customer',
  },
};
