import axios from 'axios';
import i18n from 'i18next';
import AuthorizationTokenStorage from '../auth/AuthorizationTokenStorage';
import HelperMethods from '../exports/HelperMethods';

class BackendApiClient {
  client;

  clientPure;

  authToken = this.getAuthToken();

  baseURL = this.getApiBaseUrl();

  constructor() {
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.authToken}`,
        'x-preferred-locale': i18n.language,
      },
    });
    this.clientPure = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-preferred-locale': i18n.language,
      },
    });
  }

  async requestAsync(configuration) {
    return this.clientPure
      .request(configuration)
      .then((response) => ({
        data: response.data,
        status: response.status,
        success: true,
      }))
      .catch((error) => ({
        data: error?.response?.data,
        status: error?.response?.status,
        success: false,
      }));
  }

  async requestAuthorized(configuration, formData = false) {
    const newConfig = this.createRequestConfig(configuration, formData);

    // Check if user token is not null
    if (newConfig?.headers?.Authorization.split('Bearer ')[1] === 'null') {
      return {
        data: null,
        status: 401,
        success: false,
      };
    }

    return this.client
      .request(newConfig)
      .then((response) => ({
        data: response.data,
        status: response.status,
        success: true,
      }))
      .catch((error) => {
        if (error?.response?.status === 401) {
          HelperMethods.triggerLogoutEvent();
        }

        return {
          data: error?.response?.data,
          status: error?.response?.status,
          success: false,
        };
      });
  }

  createRequestConfig(configuration, formData = false) {
    return {
      ...configuration,
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
        'Content-Type': formData ? 'multipart/form-data' : 'application/json',
        Accept: formData ? 'multipart/form-data' : 'application/json',
        'x-preferred-locale': i18n.language,
        ...(configuration?.headers ?? {}),
      },
    };
  }

  getApiBaseUrl() {
    return import.meta.env.VITE_API_BASE_URL;
  }

  setAuthToken(token) {
    this.authToken = token;

    return this;
  }

  getAuthToken() {
    return this.authToken ?? AuthorizationTokenStorage.getToken();
  }
}

export default new BackendApiClient();
