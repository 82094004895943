import { DeliveryProvider, ORDER_METHODS } from '../../../../../../../services/exports/Constants';

export default {
  header: 'Will the order take longer/shorter?',
  text: {
    [ORDER_METHODS.pickup]: ('The customer expects the order to be ready for pickup <strong>{{date}}</strong> at <strong>{{time}}</strong><br/> If necessary, you can change the time here.'),
    [ORDER_METHODS.room_service]: ('The customer expects the order to be ready for pickup <strong>{{date}}</strong> at <strong>{{time}}</strong><br/> If necessary, you can change the time here.'),
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]: ('The customer expects the order to be delivered <strong>{{date}}</strong> at <strong>{{time}}</strong><br/> If necessary, you can change the time here.'),
      [DeliveryProvider.FirstDelivery]: ('Driver will arrive to pick up order at <strong>{{time}}</strong>'),
    },
  },
  labels: {
    pickup_time: 'Pickup time',
    delivery_time: 'Delivery time',
    today: 'Today',
  },
  buttons: {
    confirm_pickup: 'Confirm new pickup time',
    confirm_delivery: 'Confirm new delivery time',
  },
};
