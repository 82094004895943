import {
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
  ERROR_CODES,
} from '../../../../../../../services/exports/Constants';

export default {
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
  },
  toasts: {
    [ERROR_CODES.no_delivery_quotes_available]: 'Leider muss die Lieferung storniert werden, da der Fahrer nicht verfügbar ist. Klicke auf die drei Punkte oben rechts, um die Bestellung zu stornieren und dem Kunden das Geld zurückzuerstatten',
    [ERROR_CODES.delivery_provider_exception]: 'Leider muss die Lieferung storniert werden, da der Fahrer nicht verfügbar ist. Klicke auf die drei Punkte oben rechts, um die Bestellung zu stornieren und dem Kunden das Geld zurückzuerstatten',
  },
};
