export default {
  total_orders: {
    title: 'Orders',
    orders: 'Orders:',
    sales_volume: 'Sales volume:',
    delivery_costs: 'Delivery costs:',
  },
  cash_orders: {
    title: 'Payment in cash/ at the door',
    orders: 'Orders:',
    sales_volume: 'Sales volume:',
    delivery_costs: 'Delivery costs:',
  },
  online_orders: {
    title: 'Online payments',
    orders: 'Orders:',
    sales_volume: 'Sales volume:',
    delivery_costs: 'Delivery costs:',
  },
  total: 'Total',
};
