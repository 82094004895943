export default {
  header: 'Bestellinformation',
  title: 'Vorbereitungszeit',
  form: {
    preparation_time: {
      placeholder: 'Eingabe der Zubereitungszeit in Minuten',
    },
  },
  labels: {
    pickup_in: 'Abholung in',
    can_you_make_it: 'Schaffst du das?',
    we_also_send_this_update_to_the_customer: 'Wir schicken diese Aktualisierung auch an den Kunden',
  },
  buttons: {
    continue: 'Weiter',
    yes_lets_go: 'Ja, los geht’s!',
    no_change_time: 'Nein, Zeitschätzung ändern',
    confirm: 'Bestätigen',
    custom: 'Benutzerdefiniert',
  },
  feedback: {
    preparation_time_required: 'Die Vorbereitungszeit muss mehr als 0 Minuten betragen',
    min_preparation_time: 'Mindestens {{minutes}} Min als Vorbereitungszeit für die externe Lieferung',
    max_preparation_time: 'Maximal {{minutes}} Min Vorbereitungszeit',
  },
};
