import React from 'react';
import { useTranslation } from 'react-i18next';
import { CModalFooter } from '@coreui/react';
import classnames from 'classnames';
import CheckCircleIcon from '../../../../resources/assets/icons/CheckCircleIcon';
import { FULFILLMENT_STATUSES } from '../../../../services/exports/Constants';

export default function OldOrder({
  order,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:OrderDetails:Footers:OldOrder' });

  const renderStatus = () => {
    if (order.fulfillment_status === FULFILLMENT_STATUSES.rejected) {
      return (
        <div className="website-text w-100 text-center text-white">
          {t('statuses.rejected', {
            reason: t(`rejection_reasons.${order.rejection_reason}`, order.rejection_reason ?? t('rejection_reasons.fully_refunded')),
          })}
        </div>
      );
    }

    return (
      <div className="website-text w-100 text-center mt-3">
        <CheckCircleIcon className="h-4 w-4 mr-2" />
        {t('statuses.fulfilled')}
      </div>
    );
  };

  return (
    <CModalFooter
      className={classnames({ 'bg-danger': order.fulfillment_status === FULFILLMENT_STATUSES.rejected })}
    >
      {renderStatus()}
    </CModalFooter>
  );
}
