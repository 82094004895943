import ReduxHooks from '../../../../store/ReduxHooks';
import { actionCreators } from '../../../../store/actions';

export default class ConnectInnerPrinterHandler {
  static type = 'CONNECT_INNER_PRINTER';

  payload = null;

  constructor(payload) {
    this.payload = payload;
  }

  notify() {
    if (this.payload?.innerPrinterConnected) {
      ReduxHooks.dispatch(actionCreators.print.setInnerPrinterConnected(true));
    }
  }
}
