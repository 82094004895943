import * as React from 'react';

function PrinterIcon({
  width,
  height,
  color,
  className,
  style,
}) {
  const _width = width ?? 16;
  const _height = height ?? _width;
  const _color = color ?? '#1C1C1E';

  return (
    <svg
      width={_width}
      height={_height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M1.7959 1.61377H8.19434V1.54785C8.19434 0.884277 7.6626 0.572266 6.92432 0.572266H3.07031C2.36279 0.572266 1.7959 0.884277 1.7959 1.54785V1.61377ZM2.64404 10.1875H7.35059C7.979 10.1875 8.29102 9.90186 8.29102 9.24707V8.50439H8.60303C9.49512 8.50439 9.98291 8.03857 9.98291 7.14209V3.60889C9.98291 2.7124 9.49512 2.24658 8.60303 2.24658H1.3916C0.530273 2.24658 0.0117188 2.7124 0.0117188 3.60889V7.14209C0.0117188 8.03857 0.495117 8.50439 1.3916 8.50439H1.69922V9.24707C1.69922 9.90186 2.01123 10.1875 2.64404 10.1875ZM7.08691 3.77148C7.08691 3.45947 7.35498 3.18701 7.67578 3.18701C7.99219 3.18701 8.26025 3.45947 8.26025 3.77148C8.26025 4.09668 7.99219 4.35156 7.67578 4.35596C7.35498 4.35596 7.08691 4.09668 7.08691 3.77148ZM2.82422 9.42285C2.61328 9.42285 2.50781 9.32178 2.50781 9.10645V5.92041C2.50781 5.70947 2.61328 5.6084 2.82422 5.6084H7.1748C7.38574 5.6084 7.48682 5.70947 7.48682 5.92041V9.10645C7.48682 9.32178 7.38574 9.42285 7.1748 9.42285H2.82422ZM3.54932 7.08057H6.44971C6.62109 7.08057 6.75293 6.94434 6.75293 6.77295C6.75293 6.61035 6.62109 6.47852 6.44971 6.47852H3.54932C3.37354 6.47852 3.24609 6.61035 3.24609 6.77295C3.24609 6.94434 3.37793 7.08057 3.54932 7.08057ZM3.54932 8.57031H6.44971C6.62109 8.57031 6.75293 8.43848 6.75293 8.27588C6.75293 8.10449 6.62109 7.97266 6.44971 7.97266H3.54932C3.37793 7.97266 3.24609 8.10449 3.24609 8.27588C3.24609 8.43848 3.37354 8.57031 3.54932 8.57031Z"
        fill={_color}
      />
    </svg>
  );
}

export default PrinterIcon;
