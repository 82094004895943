export default {
  header: 'Order information',
  title: 'Preparation time',
  form: {
    preparation_time: {
      placeholder: 'Enter preparation time in minutes',
    },
  },
  labels: {
    pickup_in: 'Pickup in',
    can_you_make_it: 'Can you make it?',
    we_also_send_this_update_to_the_customer: 'We also send this update to the customer',
  },
  buttons: {
    continue: 'Continue',
    yes_lets_go: 'Yes, let’s go!',
    no_change_time: 'No, change time',
    confirm: 'Confirm',
    custom: 'Custom',
  },
  feedback: {
    preparation_time_required: 'Preparation time has to be more than 0 minutes',
    min_preparation_time: 'Minimum {{minutes}} minutes as preparation time for external delivery',
    max_preparation_time: 'Maximum {{minutes}} minutes as preparation time',
  },
};
