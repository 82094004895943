import React from 'react';
import tw from 'twin.macro';

export default function Divider({
  className,
}) {
  return (
    <View className={className} />
  );
}

const View = tw.div`h-1 w-full bg-gray-300`;
