export default {
  text: {
    driver_found: 'Fahrer gefunden - Abholung {{time}}',
    looking_for_driver: 'Wir suchen nach dem nächsten Fahrer für dich',
    receipt: 'Der Bon wird automatisch gedruckt sobald wir einen Fahrer gefunden haben',
  },
  buttons: {
    ok: 'Okay',
  },
};
