export default {
  header: 'Drucker Einstellungen',
  settings: 'Einstellungen',
  setup: 'Drucker Einrichtung',
  sunmi_printer: 'Sunmi Drucker',
  connected_printers: 'Verbundene Drucker',
  labels: {
    auto_print: 'Automatischer Druck von Belegen',
    print_copies: 'Anzahl Kopien',
  },
  buttons: {
    test_print: 'Test drucken',
    add_printers: '+ Drucker hinzufügen',
  },
};
