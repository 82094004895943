import React from 'react';
import { CAlert } from '@coreui/react';
import classnames from 'classnames';
import InfoIcon from '../../resources/assets/icons/InfoIcon';

export default function FeedbackLabel({
  message = 'message',
  errors = null,
  // eslint-disable-next-line no-bitwise
  type = 'success' | 'error' | 'notice' | 'warning',
  className,
}) {
  function renderErrorLabel() {
    return (
      <CAlert color="danger" className={classnames('mb-0', className)}>
        <p className={!errors ? 'mb-0' : ''}>{message}</p>
        {errors
          && Object.values(errors)?.map((error) => (error ? (
            <div>
              <p>
                •
                {error}
              </p>
            </div>
          ) : null))}
      </CAlert>
    );
  }

  function renderWarningLabel() {
    return (
      <CAlert color="warning" className={className}>
        <div className="tw-flex tw-gap-2">
          <InfoIcon className="my-auto" />
          <p className={(!errors || Object.values(errors)?.length === 0) ? 'mb-0' : ''}>{message}</p>
        </div>
        {errors
          && Object.values(errors)?.map((error) => (error ? (
            <div>
              <p>
                •
                {error}
              </p>
            </div>
          ) : null))}
      </CAlert>
    );
  }

  function renderNoticeLabel() {
    return (
      <CAlert color="notice" className={classnames('p-2', className)}>
        <p className="mb-0">{message}</p>
      </CAlert>
    );
  }

  function renderSuccessLabel() {
    return <CAlert color="success">{message}</CAlert>;
  }

  function renderLabel() {
    switch (type) {
      case 'error':
        return renderErrorLabel();
      case 'warning':
        return renderWarningLabel();
      case 'notice':
        return renderNoticeLabel();
      default:
        return renderSuccessLabel();
    }
  }

  return renderLabel();
}
