import * as React from 'react';

export default function StarIcon({
  width, height, className, style,
}) {
  const _width = width ?? 25;
  const _height = height ?? 24;

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="12.5" cy="12" r="12" fill="#69CE8C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.64257 18.5098C8.50843 18.5098 8.37494 18.4683 8.26219 18.3866C8.06779 18.2454 7.9667 18.0082 8.00105 17.7698L8.60304 13.5553L6.24042 11.1921C6.0687 11.0204 6.00779 10.7677 6.08166 10.5364C6.15553 10.305 6.35253 10.1346 6.59164 10.0951L10.1544 9.50089L11.9506 5.90848C12.0614 5.68752 12.3044 5.54173 12.5338 5.55015C12.7807 5.55145 13.0049 5.69271 13.1131 5.91496L14.833 9.44775L18.476 10.0964C18.7138 10.1385 18.9082 10.3096 18.9808 10.5396C19.0534 10.7703 18.9918 11.0217 18.8208 11.1921L16.4575 13.5553L17.0601 17.7698C17.0938 18.0095 16.9927 18.248 16.7958 18.3886C16.5994 18.5298 16.3402 18.5486 16.1257 18.4398L12.576 16.6423L8.92964 18.4424C8.83892 18.4877 8.74042 18.5098 8.64257 18.5098Z" fill="#FBFBFC" />
    </svg>
  );
}
