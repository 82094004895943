import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'activeOrders',
  initialState: {
    data: [],
  },
  reducers: {
    set: (state, action) => {
      try {
        state.data = action.payload;
      } catch (error) {
        console.error('Failed to set active orders', error);
      }
    },
  },
});

export const activeOrdersProps = (state) => state;
