export default {
  toasts: {
    printing: 'Drucken...',
    print_failed: 'Drucken fehlgeschlagen. Bitte versuche es erneut.',
    orders: {
      print_one: 'Drucken {{count}} Beleg: Bestell-Nr <strong>{{orderNumber}}</strong>...',
      print_other: 'Drucken {{count}} Belege: Bestell-Nr <strong>{{orderNumber}}</strong>...',
    },
  },
};
