export default {
  text: {
    order_cancelled: 'Order canceled - Driver not available',
    wolt_support: 'Questions? Call Wolt directly <underline>{{phone_number}}</underline>',
  },
  labels: {
    dummy_order: 'Test order',
    product_one: 'Product',
    product_other: 'Products',
  },
  buttons: {
    complete_order: 'Mark as complete',
    looking_for_driver: 'Looking for a driver...',
    click_to_remove: 'Click to remove',
  },
};
