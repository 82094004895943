export default {
  title: 'Orders Portal',
  form: {
    username: {
      placeholder: 'Username',
    },
    password: {
      placeholder: 'Password',
    },
  },
  buttons: {
    submit: 'Login',
  },
  toasts: {
    auth_token_invalid: 'Supplied auth token is invalid, please sign in with credentials',
    credentials_invalid: 'Wrong username or password',
  },
};
