import React, { useMemo, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FeedbackLabel from '../components/form/FeedbackLabel';

export default function useFeedbackHandler(props = {
  renderErrors: true,
  renderToasts: false,
}) {
  const { renderErrors, renderToasts } = props;

  const [feedback, setFeedback] = useState({
    message: '',
    type: '',
  });

  const renderFeedbackToast = () => {
    if (!feedback.message) {
      return null;
    }

    if (feedback.type === 'error') {
      return toast.error(feedback.message, {
        autoClose: false,
      });
    }

    return toast(feedback.message, {
      autoClose: false,
    });
  };

  function resetFeedback() {
    setFeedback({
      message: '',
      type: '',
    });
  }

  const renderFeedbackLabel = useMemo(() => feedback?.message && (
    <div className="mt-3">
      <FeedbackLabel
        message={feedback?.message}
        type={feedback?.type}
        errors={renderErrors ? feedback?.errors : undefined}
      />
    </div>
  ), [feedback, renderErrors]);

  useEffect(() => {
    if (renderToasts) {
      renderFeedbackToast();
    }
  }, [renderToasts, feedback]);

  return {
    feedback,
    setFeedback,
    resetFeedback,
    renderFeedbackLabel,
    renderFeedbackToast,
  };
}
