import React, { useEffect, useMemo, useState } from 'react';
import AuthorizationTokenStorage from '../services/auth/AuthorizationTokenStorage';
import WebViewCommunicatorResource from '../services/resources/WebViewCommunicatorResource';
import ReduxHooks from '../store/ReduxHooks';
import ProfileManager from '../services/api/ProfileManager';
import { actionCreators } from '../store/actions';
import { AuthContext } from '../contexts/AuthContext';
import BackendApiClient from '../services/api/BackendApiClient';

export default function AuthProvider({
  children,
}) {
  const [token, setToken] = useState(AuthorizationTokenStorage.getToken());

  const isLoggedIn = !!token;

  useEffect(() => {
    if (!token) {
      return;
    }

    loadProfile();
    WebViewCommunicatorResource.forwardAuthToken({
      userToken: token,
    });
  }, [token]);

  const context = useMemo(
    () => ({
      isLoggedIn,
      logIn: async (data) => {
        try {
          await AuthorizationTokenStorage.saveToken(data.token);
          BackendApiClient.setAuthToken(data.token);
          setToken(data.token);
        } catch (error) {
          console.error('Failed to log the user in', error);
        }
      },
      logOut: () => {
        try {
          AuthorizationTokenStorage.deleteToken();
          ReduxHooks.clearReduxStore();
          setToken(null);
        } catch (error) {
          console.error('Failed to log the user out', error);
        }
      },
    }),
    [isLoggedIn],
  );

  async function loadProfile() {
    const { data, success } = await ProfileManager.get();

    if (success) {
      ReduxHooks.dispatch(actionCreators.company.set(data.data));
    }
  }

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
}
