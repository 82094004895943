import Nav from './_nav';
import Views from './views';
import Components from './components';
import Services from './services/ServicesIndex';
import Hooks from './hooks/index';

export default {
  Nav,
  Views,
  Components,
  Services,
  Hooks,
};
