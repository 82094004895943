import { ORDER_METHODS } from '../../../../../../../services/exports/Constants';

export default {
  title: {
    [ORDER_METHODS.pickup]: 'Preparation time',
    [ORDER_METHODS.delivery]: 'Delivery time',
    [ORDER_METHODS.room_service]: 'Preparation time',
  },
  text: '{{method}} in {{time}} minutes, can you do that?',
  text_scheduled: '{{method}} {{day}} at {{time}}, can you do that?',
  labels: {
    today: 'Today',
  },
  buttons: {
    confirm: 'Confirm',
  },
};
