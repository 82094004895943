export default {
  title: 'Bestellungs-Portal',
  form: {
    username: {
      placeholder: 'Benutzername',
    },
    password: {
      placeholder: 'Passwort',
    },
  },
  buttons: {
    submit: 'Anmelden',
  },
  toasts: {
    auth_token_invalid: 'Angegebenes Authentifizierungs-Token ist ungültig, bitte melden Sie sich mit Ihren Anmeldedaten an',
    credentials_invalid: 'Falsch Benutzername oder Passwort',
  },
};
